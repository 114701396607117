// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// muejeresempresarias.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyCeR1BaC9lJwuAiZ_O0QLXRzCCxBd0-eZg",
    authDomain: "mujeresempersariasunidas.firebaseapp.com",
    projectId: "mujeresempersariasunidas",
    storageBucket: "mujeresempersariasunidas.appspot.com",
    messagingSenderId: "1088527407180",
    appId: "1:1088527407180:web:254712414599eb419d7ce9",
    measurementId: "G-FJ5YX4W09T"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)