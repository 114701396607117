export const obtenerDescuento = ({valorNormal, valorRebajado}) => {

    if (valorRebajado === 0) {
        return 0
    }

    let des = (100 * valorRebajado) / valorNormal
    let num = 100 - des

    return num.toFixed(0)


}